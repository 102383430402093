















































import { get_setting_user, post_shift_scheduling } from "@/api";
import { settingShiftDetailUserType, settingUserType } from "@/api/api.types";
import { Notif } from "@/store";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component({ name: "user-shift" })
export default class UserShift extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!: string;

  @Prop({ required: true })
  readonly person!: settingShiftDetailUserType[] | null;

  /* Variables */

  userData = [] as settingUserType["data"]["_embedded"]["profile"];

  loading = false;

  items = [] as string[];

  /* Computed */

  /* Methods */

  async fetchUser(): Promise<void> {
    try {
      this.loading = true;
      const resp = await get_setting_user({
        limit: -1,
      });
      this.userData = resp.data._embedded.profile;
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  @Emit("submit")
  async submit(): Promise<void> {
    try {
      await post_shift_scheduling({
        shift: this.value,
        users: this.items.toString(),
      });
      Notif.notif_success("User(s) has been assigned.");
      this.close();
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  @Emit("input")
  close(): null {
    return null;
  }

  /* Life-cycle Methods */

  @Watch("value")
  onValueChange(now: string): void {
    if (now) {
      this.fetchUser();
      if (this.person) {
        this.items = this.person.map((t) => t.uuid);
      }
    }
  }
}
